<template>
  <basic-layout :header="campus">
    <div class="bg-white bg-opacity-90">
      <container class="">
        <section class="lg:grid lg:grid-cols-12 py-20">
          <div class="lg:col-span-10 p-8 md:p-20">
            <history-details :campus="campus" :librarians="librarians" :titles="titles" :history="history"></history-details>
          </div>
          <div class="lg:col-span-2 hidden lg:block py-28">
           <history-related-links/>
          </div>
        </section>
      </container>
    </div>
  </basic-layout>
</template>

<script>
import HistoryDetails from "../../widgets/HistoryDetails";
import BasicLayout from "../../layouts/BasicLayout";
import Container from "../../layouts/Container";
import HistoryRelatedLinks from "../../widgets/HistoryRelatedLinks";
export default {
  name: "BongabongHistory",
  components: {HistoryRelatedLinks, Container, BasicLayout, HistoryDetails},
  data:()=>({
    campus: "Bongabong Campus",
    titles: ['NAME','YEAR','SUPERINTENDENT/PRESIDENT'],
    history: ['The MinSCAT Bongabong Campus Library originally the Bongabong School of Fisheries (BSF) was established on September 21, 1975. It was converted into Bongabong College of Fisheries (BCF) on July 5, 1995 under the supervision of CHED. On February 7, 2001, BCF was integrated together with PCC Polytechnic College of Calapan and became part of MinSCAT unified campuses.',
              'MinSCAT Library was managed by different persons until in 1978 when Mrs. Rosalia F. Soriano was permanently designated as the College Librarian until she retired in 2013. On July 15, 2013, Mrs. Miriam DC. Enriquez was hired under the Presidency of Dr. Jesse T. Zamora and Campus Administrator Dr. Edna G. Piol.',
              'As of now, the Bongabong Campus library uses Rizale Integrated Library System in library management to upgrade its services and comply with the recommendations of Accrediting Agency of Chartered Colleges and Universities (AACCUP) and the CHED Technical Evaluators. It became fully operational in rendering services to the users on 2nd semester of Academic Year 2014-2015.',
              'Under the Presidency of Dr. Zamora, MBC library was renovated and improved. Additional library materials such as books and other library references, shelves, tables, chairs, and computers, were added to provide and adapt to the latest trend of the library operation.',
              'The MBC library was strategically located at the center of Education building and it is very accessible to all clienteles.',
              'The following served as librarians from 1978 to the present with their Presidents:'],
    librarians:[
      {name: 'Rosalia F. Soriano',year:'1978 – 2013',position:'College President',headNames:'Dr. Angelito A. Bacudo; Dr. Jesse T. Zamora'},
      {name: 'Miriam DC. Enriquez',year:'July 2013 – December 2016',position:'College President',headNames:'Dr. Jesse T. Zamora'},
      {name: 'Janna Ayeshma M. Dilao',year:'May 2017-present',position:'College Presidents',headNames:'Dr. Jesse T. Zamora; Dr. Levy B. Arago, Jr.'},
    ]
  })
}
</script>

<style scoped>

</style>>